import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const CompanyData = React.lazy(() => import('@izi-logistics/common/lib/pages/CompanyData'));

export default function CompanyDataPage(props) {
  return (
    <PreloadingComponent>
      <CompanyData {...props} config={{ client }} />
    </PreloadingComponent>
  );
}
